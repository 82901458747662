<script>
    import { setContext, onMount } from 'svelte';
    import BidView from '../../elementsJs/postBid/BidView.svelte';
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import Pusher from 'pusher-js';
    import toast from 'svelte-french-toast';
    import '../css/spinner.css';

    // props from marketplace controller
    export let authUser;
    export let filteredPostForPostBid;
    export let pusherKey;
    export let pusherCluster;
    export let socketsDriver;
    export let soketiHost;
    export let soketiPort;
    export let pusherScheme;

    // referralClaim Context
    const postBidContext = {
        authUser: authUser,
        filteredPostForPostBid: filteredPostForPostBid,
        pusherKey: pusherKey,
        pusherCluster,
        pusherCluster,
        socketsDriver: socketsDriver,
        soketiHost: soketiHost,
        soketiPort: soketiPort,
        pusherScheme: pusherScheme,
    };
    setContext('postBidContext', postBidContext);

    onMount(() => {
        let params = {
            cluster: pusherCluster,
        };
        if (socketsDriver === 'soketi') {
            params = {
                wsHost: soketiHost,
                wsPort: soketiPort,
                forceTLS: pusherScheme ? true : false,
            };
        }

        var pusher = new Pusher(pusherKey, params);
        pusher.logToConsole = true;
        const channel = pusher.subscribe('bmn');

        channel.bind('post-bid-update', function (data) {
            const bidFor = data.bidData.bid_for;
            const bidData = data.bidData.bid;
            const bidValidity = bidData.bid_validity;
            const bidder = data.bidData.current_bidder;
            const nowTime = data.bidData.nowTime;
            const nextPriceLimit = data.bidData.next_minimum_price;
            const auctionId = data.bidData.auction_id;

            // restart the timer
            initTimer(bidValidity, nowTime, auctionId);

            //update the price and the bidder
            document.getElementById(`current_bidder_name_${auctionId}`).innerHTML = bidder.name;
            document.getElementById(`current_bid_price_${auctionId}`).innerHTML = bidData.amount;
            document.getElementById(`min_price_${auctionId}`).innerHTML = nextPriceLimit;
            document.getElementById(`current_price_${auctionId}`).value = nextPriceLimit;

            const message = 'New update on bid ' + bidFor.name;
            toast.success(message);
        });

        pusher.connection.bind('error', function (err) {
            console.error('Pusher error:', err);
        });
    });
</script>

<svelte:component this="{MarketplaceNav}" />
<div class="col-12 pr-0 pt-4">
    <div class="px-3 pb-4">
        <h5 class="text-truncate mb-0 font-bold">Post Bid</h5>
    </div>
    <svelte:component this="{BidView}" />
</div>
